import { ChangeEvent } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  formatWithThousandSeparator,
  parseThousandSeparatedValue,
} from '#/utils/formatNumber';

interface UseFormattedNumericInputOptions {
  name: string;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
}

const useFormattedNumericInput = ({
  name,
  setValue,
  watch,
}: UseFormattedNumericInputOptions) => {
  const rawValue = watch(name); // Watch the raw value from react-hook-form

  // Format the value for display only, without changing the form state
  const formattedValue =
    rawValue !== undefined && rawValue !== null
      ? formatWithThousandSeparator(rawValue.toString())
      : '';

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const rawValue = parseThousandSeparatedValue(input);
    const numericValue = parseFloat(rawValue);

    if (!Number.isNaN(numericValue)) {
      setValue(name, numericValue); // Set the raw numeric value in form state
    } else {
      setValue(name, undefined);
    }
  };

  return {
    formattedValue,
    handleValueChange,
  };
};

export default useFormattedNumericInput;
